<template>
  <div class="product-page">
    <div class="product-image">
      <img :src="require(`@/assets/img/${product.img}`)" :alt="product.title" />
    </div>
    <div class="product-details">
      <h1 class="product-title">{{ product.title }}</h1>
      <p class="product-description">{{ product.description }}</p>
      <p class="product-price">Цена: <span>{{ product.price }} ₽</span></p>
      <div class="additional-info" v-if="product.instruction">
        <h2>Дополнительная информация</h2>
        <p v-html="product.instruction"></p>
      </div>
      <div class="button-group">
        <button @click="openModal" class="buy-button">Купить</button>
        <button @click="goBack" class="back-button">Назад</button>
      </div>
    </div>

    <!-- Модальное окно -->
    <div v-if="showModal" class="modal-overlay">

      <div class="modal">
        <h2>Введите вашу электронную почту</h2>
        <input 
          v-model="userEmail" 
          type="email" 
          placeholder="user@example.com" 
          maxlength="64"
        />

        <p>На эту почту будет отправлен чек который необходим для получения продукта.</p>

        <div class="modal-actions">
          <button @click="createOrder" class="confirm-button">Подтвердить</button>
          <button @click="closeModal" class="cancel-button">Отмена</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { shopItems } from '@/assets/db/shopItems.js';

export default {
  name: 'ProductPage',
  props: ['id'],
  data() {
    return {
      product: null,
      showModal: false,
      userEmail: '',
    };
  },
  created() {
    this.loadProduct();
  },
  methods: {
    loadProduct() {
      for (const game of shopItems) {
        const foundProduct = game.products.find(product => product.id === this.id);
        if (foundProduct) {
          this.product = foundProduct;
          this.product.instruction = (this.product.instruction) ? this.product.instruction : game.instruction;
          break;
        }
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.userEmail = ''; // Очистка поля ввода
    },
    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    },
    async createOrder() {
      if (!this.userEmail || !this.validateEmail(this.userEmail)) {
        alert('Пожалуйста, введите корректный email');
        return;
      }

      try {
        const response = await axios.post(`https://zanders.store/api/v1/payments/create-payment`, {
          amount: this.product.price * 100,

          item: {
            productId: this.product.id,
            Name: this.product.title,
            Price: this.product.price,
            Quantity: 1,
            Amount: this.product.price * 1 * 100,
            Tax: 'none',
          },

          email: this.userEmail,
        });

        if (response.data.Success && response.data.PaymentURL) {
          window.location.href = response.data.PaymentURL;
        } else {
          alert('Произошла ошибка при создании платежного намерения.');
        }
      } catch (error) {
        console.error('Ошибка при попытке создания платежного намерения:', error);
        alert('Ошибка при попытке создания платежного намерения.');
      } finally {
        this.closeModal(); // Закрываем модальное окно после обработки
      }
    }
  }
};
</script>
  
  <style scoped>
  .product-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #1f1f2f;
    color: #ffffff;
  }
  
  .product-image {
    width: 100%;
    max-width: 500px;
    margin-bottom: 20px;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .product-image img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    transition: transform 0.3s ease;
  }
  
  .product-image img:hover {
    transform: scale(1.05);
  }
  
  .product-details {
    max-width: 800px;
    text-align: center;
    padding: 20px;
    background-color: #2e2e44;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .product-title {
    font-size: 2rem;
    color: #00ffb9;
    margin-bottom: 20px;
  }
  
  .product-description {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 20px;
    color: #cccccc;
  }
  
  .product-price {
    font-size: 2rem;
    margin-bottom: 30px;
    color: #00ffb9;
    font-weight: bold;
    position: relative;
    display: inline-block;
  }
  
  .product-price::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 100%;
    height: 3px;
    background-color: #00ffb9;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease;
  }
  
  .product-price span {
    font-size: 2.5rem;
    color: #00ffb9;
    font-weight: bold;
    padding-left: 10px;
  }
  
  .product-price:hover::before {
    transform: scaleX(1);
  }
  
  .additional-info {
    text-align: left;
    margin-bottom: 30px;
    padding: 15px;
    background-color: #3b3b57;
    border-radius: 10px;
    color: #e0e0e0;
  }
  
  .additional-info h2 {
    font-size: 1.5rem;
    color: #00ffb9;
    margin-bottom: 10px;
  }
  
  .additional-info p {
    font-size: 1rem;
    line-height: 1.5;
    white-space: pre-line; /* Позволяет отображать переносы строк */
  }
  
  .button-group {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .buy-button,
  .back-button {
    background-color: #00ffb9;
    color: #1f1f2f;
    border: none;
    border-radius: 5px;
    padding: 12px 24px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .buy-button:hover,
  .back-button:hover {
    background-color: #00c895;
  }

  /* Модальное окно */
  .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Затемнение фона */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: #2e2e44;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 400px;
  width: 100%;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.modal input {
  width: 90%;
  padding: 10px;
  margin: 20px 0;
  border-radius: 5px;
  border: 1px solid #00ffb9;
  background-color: #3b3b57;
  color: #00ffb9;
}

.modal-actions {
  display: flex;
  justify-content: space-around;
}

.confirm-button,
.cancel-button {
  background-color: #00ffb9;
  color: #1f1f2f;
  border: none;
  border-radius: 5px;
  padding: 12px 24px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.confirm-button:hover,
.cancel-button:hover {
  background-color: #00c895;
}
  
  /* Медиа запросы */
  @media (min-width: 768px) {

    .product-page {
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
      padding: 40px;
    }
  
    .product-image {
      margin-right: 40px;
    }
  
    .product-details {
      text-align: left;
    }
  }
  </style>  