const aboutService = [
    {
      title: "Контакты",
      description: `
        Компания: ИП Христенко Константин Антонович<br>
        Режим работы: ежедневно с 13:00 до 21:00 по МСК<br>
        Адрес: 192288, РОССИЯ, Г САНКТ-ПЕТЕРБУРГ, ПР-КТ ДУНАЙСКИЙ, Д 53, КОРП 2, ЛИТ.А <br>
        Наш телефон: +7 (900) 633 43-30 <br>
        Наша почта: <a href="mailto:zanders.help@gmail.com">zanders.help@gmail.com</a>
        `
    },
    {
      title: 'Реквизиты компании',
      description: `
        Название организации: ИНДИВИДУАЛЬНЫЙ ПРЕДПРИНИМАТЕЛЬ ХРИСТЕНКО КОНСТАНТИН АНТОНОВИЧ <br>
        Юридический адрес организации: 192288, РОССИЯ, Г САНКТ-ПЕТЕРБУРГ, ПР-КТ ДУНАЙСКИЙ, Д 53, КОРП 2, ЛИТ.А <br>
        ИНН: 780433805005 <br>
        ОГРН/ОГРНИП: 324784700194760 <br>
        Расчетный счет: 40802810800006332412 <br>
        Банк: АО «ТБанк» <br>
        ИНН банка: 7710140679 <br>
        БИК банка: 044525974 <br>
        Корреспондентский счет банка: 30101810145250000974 <br>
        Юридический адрес банка: 127287, г. Москва, ул. Хуторская 2-я, д. 38А, стр. 26 <br>
        Основной ОКВЭД: 47.91 Торговля розничная по почте или по информационно-коммуникационной сети Интернет<br>
        Дополнительные коды ОКВЭД: 47.41 Торговля розничная компьютерами, периферийными устройствами к ним и программным обеспечением в специализированных магазинах
        `
    },
    {
        title: "Связаться с нами для возврата",
        description: `
          Для подачи заявления на возврат товара, пожалуйста, свяжитесь с нами через следующие каналы:<br>
            - Почта: <a href="mailto:zanders.help@gmail.com">zanders.help@gmail.com</a> <br>
            - Telegram: <a href="https://t.me/Zanders_Sup">@Zanders_Sup</a><br>
          Обработка запроса на возврат может занять до 14 рабочих дней. Подробные условия возврата указаны в пункте 9 нашей публичной оферты.<br>
        `
    },
    {
      title: "Условия возврата и обмена покупки",
      description: `
        1.1 Приобретая товары на нашем сайте <a href="https://zanders.store/">zanders.store</a>, покупатель соглашается с тем, что возврат денежных средств невозможен, за исключением случаев, предусмотренных настоящими условиями.<br><br>
  
        1.2 Покупатель обязуется вести непрерывную видео-съёмку с момента оплаты товара до его полного получения и проверки качества. Видео должно фиксировать процесс оплаты, получение товара и проверку его соответствия заявленным характеристикам.<br><br>
  
        1.3 В случае получения товара ненадлежащего качества, продавец оставляет за собой право отказать в возврате денежных средств или замене товара, если покупатель не представит видео-запись, подтверждающую все этапы процесса, указанные в пункте 1.2.<br><br>
  
        1.4 Предоставленная покупателем видео-запись не должна быть подвергнута монтажу или обрезке. В противном случае запрос на возврат или замену товара не будет рассмотрен.<br><br>
  
        1.5 Покупатель несёт ответственность за выполнение всех условий, описанных в этом разделе. В случае нарушения какого-либо из условий возврата, претензии покупателя могут быть отклонены.<br><br>
  
        1.6 Продавец не несёт ответственности за любые технические проблемы со стороны покупателя, которые могут повлиять на качество или возможность создания видео-записи.<br><br>
  
        1.7 Все проверки и обработка заявлений на возврат осуществляются в течение 14 рабочих дней с момента получения всех необходимых данных и подтверждений от покупателя.<br><br>
  
        1.8 Покупатель соглашается с тем, что все решения по возвратам и замене товара принимаются на усмотрение продавца и являются окончательными.<br><br>
  
        1.9 В случае нарушения покупателем правил сайта или публичной оферты, продавец имеет право отказать в предоставлении услуг без возврата денежных средств.
      `
    },
    {
        title: 'Обработка персональных данных',
        description: `
          1.1 Обработка персональных данных Покупателя осуществляется в строгом соответствии с законодательством Российской Федерации, в том числе Федеральным законом "О персональных данных". Продавец обрабатывает персональные данные Покупателя в следующих целях:<br><br>
          - предоставление Покупателю доступа к использованию функционала интернет-магазина Zanders;<br>
          - отправка таргетированной рекламы Покупателю, адаптированной под его предпочтения;<br>
          - проверка, исследование и анализ данных Покупателя для улучшения функциональности и содержания интернет-магазина;<br>
          - разработка новых разделов и функциональных возможностей интернет-магазина на основе анализа поведения и предпочтений Покупателя.<br><br>
          1.2 Продавец принимает все необходимые меры для обеспечения защиты персональных данных Покупателя от несанкционированного или случайного доступа, изменения, раскрытия, копирования, распространения, а также от иных неправомерных действий.<br><br>
          1.3 Раскрытие предоставленной Покупателем информации может быть произведено только в соответствии с действующим законодательством Российской Федерации. Это включает случаи, когда информация может быть раскрыта по требованию суда, правоохранительных органов, а также в иных установленных законодательством Российской Федерации случаях. При этом Продавец обязуется уведомить Покупателя о таких запросах, если это не запрещено законодательством.<br><br>
          1.4 Поскольку Продавец осуществляет обработку персональных данных Покупателя исключительно в целях исполнения настоящего Договора, согласие Покупателя на обработку его персональных данных в таких случаях не требуется, согласно положениям законодательства о персональных данных. Тем не менее, Продавец вправе запросить у Покупателя письменное согласие на обработку персональных данных для иных специфических целей, не предусмотренных настоящим пунктом.<br><br>
          1.5 Продавец вправе передавать персональные данные Покупателя третьим лицам (партнерским организациям, подрядчикам) исключительно для выполнения обязательств перед Покупателем и только при условии, что третьи лица обеспечивают равную степень защиты персональных данных.<br><br>
          1.6 Покупатель имеет право в любое время отозвать свое согласие на обработку персональных данных, направив Продавцу соответствующее письменное уведомление. В этом случае Продавец обязуется прекратить обработку персональных данных в течение 10 рабочих дней с момента получения уведомления, за исключением случаев, когда обработка данных необходима для выполнения обязательств по настоящему Договору или предусмотрена законодательством.
        `
    },    
    {
        title: "Публичная оферта",
        description: `
          Индивидуальный предприниматель Христенко Константин Антонович (далее — «Продавец»), действующий на основании свидетельства о государственной регистрации, имеющий ИНН 780433805005, ОГРН 324784700194760, предлагает любому заинтересованному физическому лицу (далее — «Покупатель»), заключить договор купли-продажи цифровых товаров на приведенных ниже условиях. Оферта размещена на сайте <a href="https://zanders.store/">https://zanders.store/</a>.<br><br>
    
          <strong>1. Общие положения</strong><br>
          1.1 Настоящий Договор является официальным предложением (публичной офертой) индивидуального предпринимателя Христенко Константина Антоновича (далее — «Продавец») для любого полностью дееспособного физического лица (далее — «Покупатель»), готового принять это предложение на условиях, изложенных ниже. Этот Договор регламентирует отношения между Продавцом и Покупателем в рамках продажи товаров и/или оказания услуг.<br><br>
          1.2 В соответствии с пунктом 2 статьи 437 Гражданского Кодекса Российской Федерации (ГК РФ), данное предложение является публичной офертой. Принятие Покупателем условий этой оферты (акцепт оферты) и оплата товаров и/или услуг означают заключение Договора на предложенных условиях, что подтверждено пунктом 3 статьи 438 ГК РФ. Таким образом, физическое лицо, совершившее акцепт оферты, получает статус Покупателя.<br><br>
          1.3 Момент полного и безоговорочного принятия Покупателем предложения Продавца заключить договор оферты (акцепт оферты) фиксируется при факте оплаты Покупателем заказа на условиях, изложенных в настоящей оферте. Оплата может быть произведена любым способом, указанным на официальном сайте или в иных источниках информации, предоставленных Продавцом.<br><br>
          1.4 Осуществляя акцепт Договора в порядке, определённом п. 1.3 Договора, Покупатель подтверждает свою осведомлённость и согласие с условиями Договора. Покупатель заявляет о полном и безоговорочном принятии всех условий, изложенных в Договоре, включая все приложения и дополнения, которые являются неотъемлемой частью настоящего Договора.<br><br>
          1.5 Покупатель признаёт, что акцепт Договора в порядке, указанном в п. 1.3, означает заключение договора на всех условиях, изложенных в данном документе и его приложениях. Покупатель обязуется соблюдать все правила и требования, указанные Продавцом.<br><br>
          1.6 Настоящий Договор не может быть отозван после его акцепта. В случае, если Покупатель желает внести изменения или расторгнуть договор, это возможно только с письменного согласия Продавца и в рамках действующего законодательства.<br><br>
          1.7 Настоящий Договор не требует скрепления печатями и/или подписания обеими Сторонами (далее по тексту — Стороны) и сохраняет полную юридическую силу. Акцепт оферты Покупателем и выполнение всех указанных условий являются достаточным основанием для признания этого документа юридически значимым и обязательным для обеих сторон.<br><br>
          1.8 В случае возникновения каких-либо вопросов по условиям Договора, Покупатель имеет право обратиться к Продавцу для получения дополнительных разъяснений. Все обращения должны быть направлены через официальные каналы связи, указанные Продавцом.<br><br>
    
          <strong>2. Предмет договора</strong><br>
          2.1 Настоящий договор устанавливает взаимоотношения между Продавцом и Покупателем по продаже цифровых товаров и услуг. Перечень предлагаемого цифрового контента включает, но не ограничивается, следующие позиции:<br>
          - Игровые предметы, такие как внутриигровая валюта, артефакты, экипировка и иные виды предметов, используемых в видеоиграх;<br>
          - Подписки на игровые или иные цифровые услуги, предполагающие регулярное обновление контента или предоставление доступа к определённым функциям и возможностям;<br>
          - Коды различных типов, включая активационные или промокоды для получения доступа к цифровым товарам и услугам;<br>
          - Другие виды цифрового контента, который Продавец может предложить через свои каналы продаж.<br><br>
          2.2 Покупатель обязуется ознакомиться с настоящими Правилами до оформления заказа. Информация о правилах и условиях размещена на официальном сайте Продавца и доступна в форме, позволяющей надлежащим образом ознакомиться с ними до совершения покупки. Оформление заказа Покупателем свидетельствует о полном и безоговорочном принятии условий настоящего договора, что является акцептом оферты в соответствии со ст. 438 Гражданского кодекса Российской Федерации.<br><br>
          2.3 Продавец вправе вносить изменения и дополнения в условия настоящего договора и сопроводительных правил. Продавец обязуется своевременно информировать Покупателя о таких изменениях через официальные каналы связи, указанные на сайте или иным доступным образом. Продолжение использования цифровых товаров и услуг после внесения изменений означает согласие Покупателя с обновлёнными условиями.<br><br>
          2.4 Покупатель понимает и соглашается с тем, что цифровые товары не подлежат возврату и обмену, за исключением случаев, предусмотренных действующим законодательством. В случае возникновения спорных ситуаций Покупатель имеет право обратиться к Продавцу для разрешения вопроса в порядке, установленном настоящим договором. Подробнее в п. 9.<br><br>
          2.5 Продавец обязуется предоставлять Покупателю точное и актуальное описание цифровых товаров, включая их характеристики, функциональные возможности и условия использования. Покупатель самостоятельно несёт ответственность за выбор и соответствие приобретаемых товаров своим требованиям и ожиданиям.<br><br>
          2.6 Продавец гарантирует, что все предоставляемые цифровые товары легальны, не нарушают авторских прав и соответствуют нормам действующего законодательства. В случае выявления нечестной практики со стороны Продавца, Покупатель вправе потребовать полного возмещения убытков, включая все расходы, связанные с покупкой и использованием цифровых товаров.<br><br>
    
          <strong>3. Условия оплаты и порядок расчетов</strong><br>
          3.1 Стоимость товаров, предлагаемых Продавцом, определяется в соответствии с действующими ценами, указанными на сайте <a href="https://zanders.store/">https://zanders.store/</a>. Все цены обновляются своевременно и отображаются в актуальном состоянии на момент оформления заказа. Продавец обязуется предоставлять точную информацию о стоимости товаров и услуг.<br><br>
          3.2 Продавец оставляет за собой право изменять стоимость товаров в одностороннем порядке без предварительного уведомления Покупателя. Изменения цен вступают в силу с момента их публикации на сайте <a href="https://zanders.store/">https://zanders.store/</a> и не применяются к заказам, уже принятым Продавцом к исполнению.<br><br>
          3.3 Способы оплаты указаны при оформлении платежа и включают в себя различные электронные платежные системы. Все транзакции проходят и обрабатываются через интернет-эквайринг для обеспечения безопасности и надёжности платежей.<br><br>
          3.4 Все платежи производятся исключительно в российских рублях, если иное не предусмотрено дополнительными соглашениями между Продавцом и Покупателем. В случае оплаты в иностранной валюте Покупатель несёт ответственность за все конвертационные расходы и банковские комиссии, связанные с проведением такой транзакции.<br><br>
          3.5 Покупатель самостоятельно несёт ответственность за все дополнительные расходы, связанные с проведением платежа, включая, но не ограничиваясь, банковскими комиссиями, тарифами платёжных систем и иными сопутствующими сборами. Продавец не несёт ответственности за изменение тарифов и дополнительных сборов сторонних платёжных систем.<br><br>
    
          <strong>4. Интеллектуальная собственность</strong><br>
          4.1 Вся текстовая информация и графические изображения, находящиеся на сайте Zanders (<a href="https://zanders.store/">https://zanders.store/</a>), являются собственностью Продавца.<br><br>
    
          <strong>5. Особые условия и ответственность сторон</strong><br>
          5.1 Продавец несет ответственность за своевременную передачу цифровых товаров при условии, что Покупатель выполнил все установленные требования и правила, размещенные на сайте Zanders (<a href="https://zanders.store/">https://zanders.store/</a>). Важно отметить, что Продавец гарантирует качество и соответствие товаров описанным характеристикам.<br><br>
          5.2 Продавец освобождается от ответственности за нарушение условий Договора в случае наступления обстоятельств непреодолимой силы (форс-мажор). К таким обстоятельствам относятся, но не ограничиваются:<br>
          - действия органов государственной власти;<br>
          - военные действия или террористические акты;<br>
          - пожар, наводнение, землетрясение и другие стихийные бедствия;<br>
          - вспышки эпидемий и пандемий;<br>
          - отсутствие электроэнергии или крупные сбои в энергоснабжении;<br>
          - забастовки или другие трудовые конфликты;<br>
          - гражданские волнения и беспорядки;<br>
          - сбои в работе интернета и других коммуникационных сетей и служб.<br>
          Продавец обязуется как можно раньше уведомить Покупателя о возникновении форс-мажорных обстоятельств и предпринимать все возможные меры для минимизации их последствий.<br><br>
          5.3 Продавец не несет ответственности за качество каналов связи общего пользования или услуг, предоставляемых третьими лицами, которые обеспечивают доступ Покупателя к приобретенным товарам. Это включает, но не ограничивается, качеством связи, предоставляемой интернет-провайдерами, работа сервера хостинга, стабильность и скорость соединения.<br><br>
          5.4 Покупатель несет ответственность за правильность предоставленных данных при оформлении заказа и обязуется своевременно обновлять информацию в случае её изменения. В случае предоставления недостоверных или устаревших данных, Продавец освобождается от ответственности за последствия, вызванные ошибками или задержками в передаче цифровых товаров.<br><br>
          5.5 В случае появления конфликтных ситуаций или споров, стороны обязуются решать их путем переговоров. Если решение не будет найдено, спор может быть передан на рассмотрение в суд общей юрисдикции в соответствии с действующим законодательством Российской Федерации.<br><br>
    
          <strong>6. Права и обязанности сторон</strong><br>
          6.1 Продавец обязуется передать Покупателю заказанные цифровые товары в течение 24 часов после подтверждения получения полной оплаты. Подтверждение оплаты должно быть оформлено в установленном Продавцом порядке. В случае невозможности передачи товара в указанный срок, Продавец обязуется уведомить Покупателя и предложить альтернативные варианты решения проблемы.<br><br>
          6.2 Покупатель обязуется:<br>
          - оплатить заказанные цифровые товары в полном объеме и в сроки, установленные в процессе оформления заказа;<br>
          - предоставить достоверные и актуальные данные, необходимые для выполнения заказа, включая контактную информацию и прочие требуемые сведения;<br>
          - своевременно актуализировать информацию и уведомлять Продавца об изменениях, если такие произошли в процессе исполнения Договора.<br><br>
          6.3 Покупатель имеет право:<br>
          - отказаться от заказа и получить полный возврат средств до момента его выдачи, при условии подтверждения отказа через контактные данные Продавца. Продавец обязуется в этом случае вернуть средства в течение 14 рабочих дней с момента подтверждения отказа. Подробнее в п. 9;<br>
          - получать информацию о статусе своего заказа и актуальные данные по любой интересующей его продукции.<br><br>
          6.4 Обязательства Продавца:<br>
          - предоставлять качественную поддержку Покупателям через контактные средства, указанные в разделе 11 настоящей оферты. Поддержка должна быть доступна в рабочие часы Продавца и включать консультирование по вопросам, связанным с приобретенными товарами;<br>
          - своевременно реагировать на запросы Покупателей, предоставлять необходимую информацию и разрешать возникшие проблемы в кратчайшие сроки;<br>
          - обеспечивать сохранность и конфиденциальность персональных данных Покупателей, использовать их исключительно для выполнения условий настоящего Договора.<br><br>
          6.5 Права Продавца:<br>
          - приостановить выполнение обязательств по данному Договору в случае нарушения Покупателем условий оплаты или предоставления недостоверных данных;<br>
          - вносить изменения в правила и условия оферты при условии уведомления Покупателей, если такие изменения существенно затрагивают их права и обязанности.<br><br>
          6.6 Покупатель обязуется:<br>
          - внимательно ознакомиться с условиями данного Договора перед совершением оплаты и оформления заказа;<br>
          - соблюдать правила и рекомендации, связанные с использованием приобретенных цифровых товаров, чтобы избежать их некорректного использования и потенциальных последствий.<br><br>
    
          <strong>7. Обработка персональных данных</strong><br>
          7.1 Обработка персональных данных Покупателя осуществляется в строгом соответствии с законодательством Российской Федерации, в том числе Федеральным законом "О персональных данных". Продавец обрабатывает персональные данные Покупателя в следующих целях:<br>
          - предоставление Покупателю доступа к использованию функционала интернет-магазина Zanders;<br>
          - отправка таргетированной рекламы Покупателю, адаптированной под его предпочтения;<br>
          - проверка, исследование и анализ данных Покупателя для улучшения функциональности и содержания интернет-магазина;<br>
          - разработка новых разделов и функциональных возможностей интернет-магазина на основе анализа поведения и предпочтений Покупателя.<br><br>
          7.2 Продавец принимает все необходимые меры для обеспечения защиты персональных данных Покупателя от несанкционированного или случайного доступа, изменения, раскрытия, копирования, распространения, а также от иных неправомерных действий.<br><br>
          7.3 Раскрытие предоставленной Покупателем информации может быть произведено только в соответствии с действующим законодательством Российской Федерации. Это включает случаи, когда информация может быть раскрыта по требованию суда, правоохранительных органов, а также в иных установленных законодательством Российской Федерации случаях. При этом Продавец обязуется уведомить Покупателя о таких запросах, если это не запрещено законодательством.<br><br>
          7.4 Поскольку Продавец осуществляет обработку персональных данных Покупателя исключительно в целях исполнения настоящего Договора, согласие Покупателя на обработку его персональных данных в таких случаях не требуется, согласно положениям законодательства о персональных данных. Тем не менее, Продавец вправе запросить у Покупателя письменное согласие на обработку персональных данных для иных специфических целей, не предусмотренных настоящим пунктом.<br><br>
          7.5 Продавец вправе передавать персональные данные Покупателя третьим лицам (партнерским организациям, подрядчикам) исключительно для выполнения обязательств перед Покупателем и только при условии, что третьи лица обеспечивают равную степень защиты персональных данных.<br><br>
          7.6 Покупатель имеет право в любое время отозвать свое согласие на обработку персональных данных, направив Продавцу соответствующее письменное уведомление. В этом случае Продавец обязуется прекратить обработку персональных данных в течение 10 рабочих дней с момента получения уведомления, за исключением случаев, когда обработка данных необходима для выполнения обязательств по настоящему Договору или предусмотрена законодательством.<br><br>
    
          <strong>8. Порядок рассмотрения претензий и споров</strong><br>
          8.1 Претензии Покупателя по предоставляемым услугам принимаются Продавцом к рассмотрению исключительно в письменном виде, направленном по электронной почте, указанной в разделе 11 настоящей оферты. Претензии должны быть поданы в течение 2 рабочих дней с момента возникновения спорной ситуации и включать детальное описание проблемы и соответствующие доказательства.<br><br>
          8.2 При рассмотрении спорных ситуаций Продавец имеет право запросить у Покупателя дополнительную документацию и материалы, необходимые для объективного анализа ситуации. Это может включать, но не ограничивается, следующими видами документов: доказательства оплаты, скриншоты, видео и фото. В случае непредоставления Покупателем запрашиваемых документов в течение 1 рабочего дня с момента получения запроса, претензия считается безосновательной и дальнейшему рассмотрению Продавцом не подлежит.<br><br>
          8.3 В случае невозможности урегулирования спора в досудебном порядке, стороны вправе обратиться в соответствующий судебный орган в установленном законодательством порядке. При этом место разрешения споров определяется исходя из местонахождения Продавца, если иной порядок не установлен действующим законодательством или отдельным соглашением сторон.<br><br>
          8.4 Продавец не несет ответственности за убытки Покупателя, возникшие по причинам, не связанным с неисполнением или ненадлежащим исполнением Продавцом своих обязательств по настоящей Оферте, в том числе за убытки, вызванные действиями третьих лиц или самим Покупателем.<br><br>
    
          <strong>9. Условия возвратов</strong><br>
          9.1 Приобретая товары на нашем сайте <a href="https://zanders.store/">https://zanders.store/</a>, покупатель соглашается с тем, что возврат денежных средств невозможен, за исключением случаев, предусмотренных настоящими условиями.<br><br>
          9.2 Покупатель обязуется вести непрерывную видео-съёмку с момента оплаты товара до его полного получения и проверки качества. Видео должно фиксировать процесс оплаты, получение товара и проверку его соответствия заявленным характеристикам.<br><br>
          9.3 В случае получения товара ненадлежащего качества, продавец оставляет за собой право отказать в возврате денежных средств или замене товара, если покупатель не представит видео-запись, подтверждающую все этапы процесса, указанные в пункте 1.2.<br><br>
          9.4 Предоставленная покупателем видео-запись не должна быть подвергнута монтажу или обрезке. В противном случае запрос на возврат или замену товара не будет рассмотрен.<br><br>
          9.5 Покупатель несёт ответственность за выполнение всех условий, описанных в этом разделе. В случае нарушения какого-либо из условий возврата, претензии покупателя могут быть отклонены.<br><br>
          9.6 Продавец не несёт ответственности за любые технические проблемы со стороны покупателя, которые могут повлиять на качество или возможность создания видео-записи.<br><br>
          9.7 Все проверки и обработка заявлений на возврат осуществляются в течение 14 рабочих дней с момента получения всех необходимых данных и подтверждений от покупателя.<br><br>
          9.8 Покупатель соглашается с тем, что все решения по возвратам и замене товара принимаются на усмотрение продавца и являются окончательными.<br><br>
          9.9 В случае нарушения покупателем правил сайта или публичной оферты, продавец имеет право отказать в предоставлении услуг без возврата денежных средств.<br><br>
    
          <strong>10. Прочие условия</strong><br>
          10.1 Покупатель обладает всеми правами и полномочиями, необходимыми для заключения и исполнения договора. Покупатель подтверждает, что у него нет юридических препятствий для совершения покупки цифровых товаров на сайте Zanders и он действует исключительно в рамках правового поля.<br><br>
          10.2 Покупатель вправе в любое время в одностороннем порядке отказаться от услуг Продавца. В случае одностороннего отказа Покупателя от услуг Продавца произведенная оплата не возвращается, но может быть перенесена на другую услугу по согласованию с Продавцом. Продавец оставляет за собой право отклонить перенос оплаты в случае отсутствия аналогичных или доступных услуг.<br><br>
          10.3 Продавец оставляет за собой право изменять или дополнять любые из условий настоящего договора в любое время, опубликовывая все изменения на сайте Zanders. Уведомление о внесении изменений направляется Покупателю через официальные каналы связи на сайте. Продолжение использования услуг после публикации изменений автоматически означает согласие Покупателя с новыми условиями.<br><br>
          10.4 По всем вопросам, не урегулированным настоящим договором, стороны руководствуются действующим законодательством Российской Федерации. В случае возникновения спорных вопросов или разногласий, стороны стремятся решить их путем переговоров. Если стороны не могут достичь соглашения, спор подлежит рассмотрению в соответствующем суде по месту нахождения Продавца.<br><br>
          10.5 Признание судом недействительности какого-либо положения настоящего договора не влечет за собой недействительность остальных положений. Все остальные условия остаются в силе и продолжают действовать.<br><br>
    
          <strong>11. Контактная информация</strong><br>
          Индивидуальный предприниматель: Христенко Константин Антонович<br>
          ИНН: 780433805005<br>
          ОГРН: 324784700194760<br>
          Номер телефона: +79006334330<br>
          Электронная почта: <a href="mailto:zanders.help@gmail.com">zanders.help@gmail.com</a><br><br>
          Данная оферта вступает в силу с момента её размещения на сайте Zanders (<a href="https://zanders.store/">https://zanders.store/</a>) и действует до момента её отзыва Продавцом.<br><br>
          Дата размещения оферты: 18.08.2024<br><br>
          С уважением,<br>
          ИП Христенко Константин Антонович
        `
      },
  ];  

module.exports = aboutService